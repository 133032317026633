
import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import axios from '../../Utils/axios'
import { cancellContacts, cancellContactsDate } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';

export default function CancellContactTable() {
    const {locationId} = useParams()
    const cancellContactsUrl = `${cancellContacts}${locationId}`
    const cancellContactsDateUrl = `${cancellContactsDate}${locationId}`
    const [contacts, setContacts] = useState(null);
    // const [isMounted, setIsMounted] = useState(false)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        full_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        payment_method: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        amount_paid: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        error_message: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        form_submitted: { value: null, matchMode: FilterMatchMode.EQUALS },
        club_token_fetch: { value: null, matchMode: FilterMatchMode.EQUALS },
        confirmation_page_showed: { value: null, matchMode: FilterMatchMode.EQUALS },
        confirmation_page_submitted: { value: null, matchMode: FilterMatchMode.EQUALS },
        membership_cancelled: { value: null, matchMode: FilterMatchMode.EQUALS },
        due_amounts_updated: { value: null, matchMode: FilterMatchMode.EQUALS },
        secondary_webhook_triggred: { value: null, matchMode: FilterMatchMode.EQUALS },
        member_status: { value: null, matchMode: FilterMatchMode.IN },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [statuses] = useState(['Pay Now', 'Pay Later']);
    // const [memberStatuses] = useState([
    //     { name: 'Active', code: 'AC' },
    //     { name: 'Cancelled', code: 'CN' },
    //     { name: 'Expired', code: 'EX' },
    //     { name: 'Freeze', code: 'FZ' },
    //     { name: 'Pending Cancel', code: 'PC' },
    //     { name: 'Ok', code: 'OK' },
    //     { name: 'Need Address', code: 'NA' }
    // ]);
    const [memberStatuses] = useState(['Active', 'Cancelled', 'Expired', 'Freeze', 'Pending Cancel', 'Ok', 'Need Address']);
    const [dates, setDates] = useState(null);

    const getSeverity = (status) => {
        switch (status) {
            case 'Pay Now':
                return 'info';
    
            case 'Pay Later':
                return 'warning';
    
            default:
                return 'default-severity';
        }
    };

    useEffect(()=>{
        setLoading(true);
        axios.get(cancellContactsUrl, {
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setContacts(response.data);
                    setLoading(false);
                  }
                else {
                    setContacts(null) 
                  }
                })
            .catch((error)=>{
                setContacts(null)     
            })
    },[cancellContactsUrl])

    useEffect(() => {
        if (dates === null || (dates && dates[1] !== null)){
            setLoading(true);
            const localDates = dates?.map(date => new Date(date.getTime() - date.getTimezoneOffset() * 60000));
            const data = {
                'date_range' : localDates
            }

            axios.post(cancellContactsDateUrl, data,{
                headers: {'Content-Type': 'application/json' },
            })
                .then((response) => {
                    if (response.status === 200) {
                        setContacts(response.data);
                        setLoading(false);            
                    }
                    })
                .catch((error)=>{
                    console.log(error)
                    // toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});          
                })
        }
      }, [cancellContactsDateUrl, dates]);


    // const getCustomers = (data) => {
    //     return [...(data || [])].map((d) => {
    //         d.form_submitted_date = new Date(d.form_submitted_date);

    //         return d;
    //     });
    // };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-space-between cancelled-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>

                <span className="p-float-label filter-card-container">
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" showButtonBar className="full-width"/>
                    <label htmlFor="ms-cities">Select Date Range</label>
                </span>
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return rowData.payment_method ? (
            <Tag value={rowData.payment_method} severity={getSeverity(rowData.payment_method)} />
        ) : (
            <span></span>
        );
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const formSubmittedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.form_submitted, 'false-icon pi-times-circle': !rowData.form_submitted })} style={{ color: rowData.form_submitted ? 'green' : 'red', fontSize: '1.3rem'}}></i>;
    };

    const tokenBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.club_token_fetch, 'false-icon pi-times-circle': !rowData.club_token_fetch })} style={{ color: rowData.club_token_fetch ? 'green' : 'red', fontSize: '1.3rem'}}></i>;
    };

    const confirmationShowedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.confirmation_page_showed, 'false-icon pi-times-circle': !rowData.confirmation_page_showed })} style={{ color: rowData.confirmation_page_showed ? 'green' : 'red', fontSize: '1.3rem'}}></i>;
    };

    const confirmationSubmittedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.confirmation_page_submitted, 'false-icon pi-times-circle': !rowData.confirmation_page_submitted })} style={{ color: rowData.confirmation_page_submitted ? 'green' : 'red', fontSize: '1.3rem'}}></i>;
    };

    const secondWebhookBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.secondary_webhook_triggred, 'false-icon pi-times-circle': !rowData.secondary_webhook_triggred })} style={{ color: rowData.secondary_webhook_triggred ? 'green' : 'red', fontSize: '1.3rem'}}></i>;
    };

    const dueAmountsUpdatedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.due_amounts_updated, 'false-icon pi-times-circle': !rowData.due_amounts_updated })} style={{ color: rowData.due_amounts_updated ? 'green' : 'red', fontSize: '1.3rem'}}></i>;
    };

    const cancelledBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.membership_cancelled, 'false-icon pi-times-circle' : !rowData.membership_cancelled })} style={{ color: rowData.membership_cancelled ? 'green' : 'red', fontSize: '1.3rem'}}></i>;
    };

    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Payment Method" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };

    const memberStatusBodyTemplate = (rowData) => {
        const member_status = rowData.member_status;

        return (
            <div className="flex align-items-center gap-2">
                <span>{member_status}</span>
            </div>
        );
    };

    const memberStatusItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };


    const memberStatusRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={memberStatuses}
                itemTemplate={memberStatusItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                // optionLabel="name"
                placeholder="Member Status"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const verifiedRowFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const header = renderHeader();

    return (
        <div className="card">
            <DataTable value={contacts} paginator dataKey="contact_id" filters={filters} filterDisplay="row" loading={loading} scrollable scrollHeight="70vh" 
                rows={50} rowsPerPageOptions={[50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}"
                globalFilterFields={['full_name', 'payment_method', 'amount_paid', 'error_message', 'member_status']} header={header} emptyMessage="No contacts found.">
                <Column field="full_name" header="Name" style={{ minWidth: '12rem' }} />
                <Column field="submitted_date" header="Form Submitted Date" style={{ minWidth: '13rem' }} />
                <Column field="form_submitted" header="Cancellation Form Submitted" dataType="boolean" style={{ minWidth: '6rem' }} body={formSubmittedBodyTemplate} filter filterElement={verifiedRowFilterTemplate} />
                <Column field="club_token_fetch" header="MyiClub Consent Received" dataType="boolean" style={{ minWidth: '6rem' }} body={tokenBodyTemplate} filter filterElement={verifiedRowFilterTemplate} />
                <Column field="confirmation_page_showed" header="Confirmation Page Showed" dataType="boolean" style={{ minWidth: '6rem' }} body={confirmationShowedBodyTemplate} filter filterElement={verifiedRowFilterTemplate} />
                <Column field="confirmation_page_submitted" header="Confirmation Submitted" dataType="boolean" style={{ minWidth: '6rem' }} body={confirmationSubmittedBodyTemplate} filter filterElement={verifiedRowFilterTemplate} />
                <Column field="secondary_webhook_triggred" header="MyiClub Consent Not Received, Request Submitted" dataType="boolean" style={{ minWidth: '6rem' }} body={secondWebhookBodyTemplate} filter filterElement={verifiedRowFilterTemplate} />
                <Column field="due_amounts_updated" header="Due Amounts Updated" dataType="boolean" style={{ minWidth: '6rem' }} body={dueAmountsUpdatedBodyTemplate} filter filterElement={verifiedRowFilterTemplate} />
                <Column field="membership_cancelled" header="Membership Cancelled" dataType="boolean" style={{ minWidth: '6rem' }} body={cancelledBodyTemplate} filter filterElement={verifiedRowFilterTemplate} />
                <Column field="payment_method" header="Payment Method" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={statusBodyTemplate} filter filterElement={statusRowFilterTemplate} />
                <Column field="amount_paid" header="Paid Amount" style={{ minWidth: '8rem' }} />
                <Column header="Member Status" filterField='member_status' showFilterMenu={false} filterMenuStyle={{ width: '10rem' }} style={{ minWidth: '10rem' }} body={memberStatusBodyTemplate} filter filterElement={memberStatusRowFilterTemplate} />
                <Column field="last_day_of_access" header="Last Day Of Access" style={{ minWidth: '10rem' }} />
                <Column field="error_message" header="Error Message" style={{ minWidth: '20rem' }} />
            </DataTable>
        </div>
    );
}
        