
import React, { useState, useEffect, useRef } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import axios from '../../Utils/axios'
import { mergeSubmit } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default function MergeTable({ data, handMergeDataEmpty, mergeContactCount, mergeContactList }) {
    const {locationId} = useParams()
    const mergeSubmitUrl = `${mergeSubmit}${locationId}`
    const toast = useRef(null);
    const [visible, setVisible] = useState(true);
    const [mergeData, setMergeData] = useState(data);
    const [columnWidth, setcolumnWidth] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setcolumnWidth(100/(mergeContactCount + 1))
      setMergeData(data)
    }, [data, mergeContactCount, mergeContactList]);

  const handleDialogHide = () => {
    handMergeDataEmpty()
    setVisible(false)
  }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">Merge Contacts</span>
        </div>
    );

    const submitMergeData = () =>{
      setLoading(true)
      const data = {
        mergeData,
        mergeContactList
      }

      axios.post(mergeSubmitUrl, data, {
        headers: {'Content-Type': 'application/json'},
      })
        .then((response) => {
            if (response.status === 200) {
              setLoading(false)
              handleDialogHide()
              }
            else {
              toast.current.show({ severity:'error', summary: 'Failed To Merge', life: 3000});
              }
            })
        .catch((error)=>{
          toast.current.show({ severity:'error', summary: 'Failed To Merge', life: 3000});
        })
    }

    const accept = () => {
      submitMergeData()
      // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const confirm1 = () => {
      confirmDialog({
          message: 'Are you sure you want to proceed?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          defaultFocus: 'accept',
          accept,
          reject
      });
  };

    const footerContent = (
        <div>
            {/* <Button label="RESOLVE" icon="pi pi-check" onClick={submitMergeData} autoFocus /> */}
            <Button label="RESOLVE" icon="pi pi-check" onClick={confirm1} autoFocus />
        </div>
    );

    const setSelectedValue = (field, value, name) => {
      // console.log(value)
      // Find the index of the element with the specified field
      const index = mergeData.findIndex((element) => element.field === field);

      if (index !== -1) {
        // Create a copy of the array to avoid directly modifying state
        const updatedData = [...mergeData];

        // Update the value1 of the specified field with the new value
        updatedData[index].selected_value = value;
        
        if (name === 'Member ID'){
          const member_type = mergeData.findIndex((element) => element.name === 'Membership Type');
          const member_plan = mergeData.findIndex((element) => element.name === 'Membership Agreement Plan');
          const member_status = mergeData.findIndex((element) => element.name === 'Member Status');
          const member_join_status = mergeData.findIndex((element) => element.name === 'Join Status');
          const member_since_date = mergeData.findIndex((element) => element.name === 'Member Agreement Since Date');
          const member_ex_date = mergeData.findIndex((element) => element.name === 'Member Agreement Expiration Date');

          if (member_type !== -1) {
            updatedData[member_type].selected_value = updatedData[index][value]['Membership Type'];
          }

          if (member_plan !== -1) {
            updatedData[member_plan].selected_value = updatedData[index][value]['Membership Agreement Plan'];
          }

          if (member_status !== -1) {
            updatedData[member_status].selected_value = updatedData[index][value]['Member Status'];
          }

          if (member_join_status !== -1) {
            updatedData[member_join_status].selected_value = updatedData[index][value]['Join Status'];
          }

          if (member_since_date !== -1) {
            updatedData[member_since_date].selected_value = updatedData[index][value]['Member Agreement Since Date'];
          }

          if (member_ex_date !== -1) {
            updatedData[member_ex_date].selected_value = updatedData[index][value]['Member Agreement Expiration Date'];
          }
        }
        // Update the state with the modified data
        setMergeData(updatedData);
      }
    }

    const setSelectedCheckBoxValue = (checked, value, field) => {

      const index = mergeData.findIndex((element) => element.field === field);

      if (index !== -1) {
        // Create a copy of the array to avoid directly modifying state
        const updatedData = [...mergeData];

        // Update the value1 of the specified field with the new value
        if (checked){
          updatedData[index].selected_value.push(value);
        }
        else{
          const valueIndex = updatedData[index].selected_value.indexOf(value);
          if (valueIndex !== -1) {
            updatedData[index].selected_value.splice(valueIndex, 1);
          }
        }

        // Update the state with the modified data
        setMergeData(updatedData);
      }
  }

  const setSelectedCheckBoxValueWithId = (checked, value, field, value_id) => {

    const index = mergeData.findIndex((element) => element.field === field);
    const matchingId = value_id.find(item => item.id === value);

    if (index !== -1) {
      // Create a copy of the array to avoid directly modifying state
      const updatedData = [...mergeData];

      // Update the value1 of the specified field with the new value
      if (checked){
        updatedData[index].selected_value.push(matchingId.id);
        updatedData[index].selected_value_names.push(matchingId.name);
      }
      else{
        const valueIndex = updatedData[index].selected_value.indexOf(matchingId.id);
        if (valueIndex !== -1) {
          updatedData[index].selected_value.splice(valueIndex, 1);
        }

        const valueIndexName = updatedData[index].selected_value_names.indexOf(matchingId.name);
        if (valueIndexName !== -1) {
          updatedData[index].selected_value_names.splice(valueIndexName, 1);
        }

        // if (updatedData[index].selected_value_names) {
        //   updatedData[index].selected_value_names = updatedData[index].selected_value_names.filter(
        //     (selectedValue) => selectedValue !== value
        //   );
        // }
      }

      // Update the state with the modified data
      setMergeData(updatedData);
      console.log(updatedData)
    }
}

    const value1Body = (rowData) => {
      const value = rowData.value1
      const valueId1 = rowData.valueId1
      // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
      const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
      return(
          <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
            {Array.isArray(value) ? (
              Array.isArray(valueId1) ? (
                valueId1.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId1)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value1"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value1 === rowData.selected_value}
                  />
                  <label htmlFor="value1" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value1"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value1 === rowData.selected_value}
                  />
                  <label htmlFor="value1" className="ml-2">{value}</label>
                </>
              )
            )
            }
          </div>
      )
    }

    const value2Body = (rowData) => {
        const value = rowData.value2
        const valueId2 = rowData.valueId2
        // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
        const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
        return(
          <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
            {Array.isArray(value) ? (
              Array.isArray(valueId2) ? (
                valueId2.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId2)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value2"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value2 === rowData.selected_value}
                  />
                  <label htmlFor="value2" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value2"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value2 === rowData.selected_value}
                  />
                  <label htmlFor="value2" className="ml-2">{value}</label>
                </>
              )
            )
            }
          </div>
        )
    }

    const value3Body = (rowData) => {
        const value = rowData.value3
        const valueId3 = rowData.valueId3
        // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
        const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
        return(
          <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
            {Array.isArray(value) ? (
              Array.isArray(valueId3) ? (
                valueId3.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId3)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value3"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value3 === rowData.selected_value}
                  />
                  <label htmlFor="value3" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value3"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value3 === rowData.selected_value}
                  />
                  <label htmlFor="value3" className="ml-2">{value}</label>
                </>
              )
            )
            }
          </div>
        )
    }

    const value4Body = (rowData) => {
      const value = rowData.value4
      const valueId4 = rowData.valueId4
      // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
      const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
      return(
        <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
          {Array.isArray(value) ? (
              Array.isArray(valueId4) ? (
                valueId4.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId4)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value4"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value4 === rowData.selected_value}
                  />
                  <label htmlFor="value4" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value4"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value4 === rowData.selected_value}
                  />
                  <label htmlFor="value4" className="ml-2">{value}</label>
                </>
              )
            )
            }
        </div>
      )
    }

  const value5Body = (rowData) => {
    const value = rowData.value5
    const valueId5 = rowData.valueId5
    // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
    const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
    return(
      <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
        {Array.isArray(value) ? (
              Array.isArray(valueId5) ? (
                valueId5.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId5)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value5"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value5 === rowData.selected_value}
                  />
                  <label htmlFor="value5" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value5"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value5 === rowData.selected_value}
                  />
                  <label htmlFor="value5" className="ml-2">{value}</label>
                </>
              )
            )
            }
      </div>
    )
  }

  const value6Body = (rowData) => {
    const value = rowData.value6
    const valueId6 = rowData.valueId6
    // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
    const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
    return(
      <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
        {Array.isArray(value) ? (
              Array.isArray(valueId6) ? (
                valueId6.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId6)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value6"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value6 === rowData.selected_value}
                  />
                  <label htmlFor="value6" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value6"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value6 === rowData.selected_value}
                  />
                  <label htmlFor="value6" className="ml-2">{value}</label>
                </>
              )
            )
            }
      </div>
    )
  }

  const value7Body = (rowData) => {
    const value = rowData.value7
    const valueId7 = rowData.valueId7
    // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
    const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
    return(
      <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
        {Array.isArray(value) ? (
              Array.isArray(valueId7) ? (
                valueId7.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId7)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value7"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value7 === rowData.selected_value}
                  />
                  <label htmlFor="value7" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value7"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value7 === rowData.selected_value}
                  />
                  <label htmlFor="value7" className="ml-2">{value}</label>
                </>
              )
            )
            }
      </div>
    )
  }

  const value8Body = (rowData) => {
    const value = rowData.value8
    const valueId8 = rowData.valueId8
    // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
    const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
    return(
      <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
        {Array.isArray(value) ? (
              Array.isArray(valueId8) ? (
                valueId8.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId8)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value8"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value8 === rowData.selected_value}
                  />
                  <label htmlFor="value8" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value8"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value8 === rowData.selected_value}
                  />
                  <label htmlFor="value8" className="ml-2">{value}</label>
                </>
              )
            )
            }
      </div>
    )
  }

  const value9Body = (rowData) => {
    const value = rowData.value9
    const valueId9 = rowData.valueId9
    // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
    const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
    return(
      <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
        {Array.isArray(value) ? (
              Array.isArray(valueId9) ? (
                valueId9.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId9)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value9"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value9 === rowData.selected_value}
                  />
                  <label htmlFor="value9" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value9"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value9 === rowData.selected_value}
                  />
                  <label htmlFor="value9" className="ml-2">{value}</label>
                </>
              )
            )
            }
      </div>
    )
  }

  const value10Body = (rowData) => {
    const value = rowData.value10
    const valueId10 = rowData.valueId10
    // const extraFields = ['opportunity', 'appointment', 'notes', 'task'];
    const memberFields = ['Membership Type', 'Membership Agreement Plan', 'Member Agreement Expiration Date', 'Member Status', 'Join Status', 'Member Agreement Since Date']
    return(
      <div className={Array.isArray(value) ? "checkbox-flex align-items-center": "radio-flex align-items-center"}>
        {Array.isArray(value) ? (
              Array.isArray(valueId10) ? (
                valueId10.map((category) => (
                  <div key={category.id} className="flex align-items-center">
                    <Checkbox
                      key={category.id}
                      inputId={category.id}
                      name={category.name}
                      value={category.id}
                      onChange={(e) => {setSelectedCheckBoxValueWithId(e.checked, e.value,  rowData.field, valueId10)}}
                      checked={rowData.selected_value.includes(category.id)}
                    />
                    <label htmlFor="value2" className="ml-2">{category.name}</label>
                  </div>
                ))
              ) : (
                value.map((category) => (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      key={category.index}
                      inputId={category.index}
                      name={category}
                      value={category}
                      onChange={(e) => {setSelectedCheckBoxValue(e.checked, e.value, rowData.field)}}
                      checked={rowData.selected_value.includes(category)}
                    />
                    <label htmlFor="value2" className="ml-2">{category}</label>
                  </div>
                ))
              )
            )
            :
            (
              memberFields.includes(rowData.name) ? (
                <>
                  <RadioButton
                    disabled
                    inputId="value10"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value10 === rowData.selected_value}
                  />
                  <label htmlFor="value10" className="ml-2">{value}</label>
                </>
              ) : (
                <>
                  <RadioButton
                    inputId="value10"
                    name={value}
                    value={value}
                    onChange={(e) => setSelectedValue(rowData.field, value, rowData.name)}
                    checked={rowData.value10 === rowData.selected_value}
                  />
                  <label htmlFor="value10" className="ml-2">{value}</label>
                </>
              )
            )
            }
      </div>
    )
  }



    const columnsByMergeContactCount = {
      1: [
        { field: 'value1', body: value1Body },
      ],
      2: [
        { body: value1Body },
        { field: 'value2', body: value2Body }
      ],
      3: [
        { field: 'value1', body: value1Body },
        { field: 'value2', body: value2Body },
        { field: 'value3', body: value3Body }
      ],
      4: [
        { field: 'value1', body: value1Body },
        { field: 'value2', body: value2Body },
        { field: 'value3', body: value3Body },
        { field: 'value4', body: value4Body }
      ],
      5: [
        { field: 'value1', body: value1Body },
        { field: 'value2', body: value2Body },
        { field: 'value3', body: value3Body },
        { field: 'value4', body: value4Body },
        { field: 'value5', body: value5Body }
      ],
      6: [
        { field: 'value1', body: value1Body },
        { field: 'value2', body: value2Body },
        { field: 'value3', body: value3Body },
        { field: 'value4', body: value4Body},
        { field: 'value5', body: value5Body },
        { field: 'value6', body: value6Body }
      ],
      7: [
        { field: 'value1', body: value1Body },
        { field: 'value2', body: value2Body },
        { field: 'value3', body: value3Body },
        { field: 'value4', body: value4Body },
        { field: 'value5', body: value5Body },
        { field: 'value6', body: value6Body },
        { field: 'value7', body: value7Body }
      ],
      8: [
        { field: 'value1', body: value1Body },
        { field: 'value2', body: value2Body },
        { field: 'value3', body: value3Body },
        { field: 'value4', body: value4Body },
        { field: 'value5', body: value5Body },
        { field: 'value6', body: value6Body },
        { field: 'value7', body: value7Body },
        { field: 'value8', body: value8Body }
      ],
      9: [
        { field: 'value1', body: value1Body },
        { field: 'value2', body: value2Body },
        { field: 'value3', body: value3Body },
        { field: 'value4', body: value4Body },
        { field: 'value5', body: value5Body },
        { field: 'value6', body: value6Body },
        { field: 'value7', body: value7Body },
        { field: 'value8', body: value8Body },
        { field: 'value9', body: value9Body }
      ],
      10: [
        { field: 'value1', body: value1Body },
        { field: 'value2', body: value2Body },
        { field: 'value3', body: value3Body },
        { field: 'value4', body: value4Body },
        { field: 'value5', body: value5Body },
        { field: 'value6', body: value6Body },
        { field: 'value7', body: value7Body },
        { field: 'value8', body: value8Body },
        { field: 'value9', body: value9Body },
        { field: 'value10', body: value10Body }
      ],
    };
  
    // Select the appropriate set of columns based on mergeContactCount
    const selectedColumns = columnsByMergeContactCount[mergeContactCount];
  

    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast} />
            <ConfirmDialog />
            <Dialog visible={visible} modal header={headerElement} footer={footerContent} style={{ width: '90%' }} onHide={handleDialogHide}>
              <DataTable className="merge-data-table" scrollable scrollHeight="65vh" value={mergeData} loading={loading} tableStyle={{width: 'fit-content' }}>
                  <Column field="name" className="font-bold" style={{minWidth: `20rem`, fontWeight:'700'}}></Column>
                  {/* {Array.from({ length: mergeContactCount }, (_, index) => (
                    <Column key={`value${index + 1}`} field={`value${index + 1}`} style={{minWidth: `${columnWidth}%`}}></Column>
                  ))} */}
                  {selectedColumns.map((column, index) => (
                    <Column key={index} {...column} style={{width: `${columnWidth}%`}} />
                ))}
              </DataTable>
            </Dialog>
        </div>
    )
}
        




