import './App.css';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import ContactsTable from './Components/ContactTable/ContactTable';
import CancellContactTable from './Components/CancellContactTable/CancellContactTable';
import OnboardForm from './Components/OnboardForm/OnboardForm';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<OnboardForm/>} />
          <Route path="/:locationId" element={<ContactsTable/>} />
          <Route path="cancelled_contacts/:locationId" element={<CancellContactTable/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
