import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './table.css'
import React, { useState, useEffect,useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from '../../Utils/axios'
import { mergeContactsUrl, mergeData } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';
import { Toast } from 'primereact/toast';
import MergeTable from "../MergeTable/MergeTable";

export default function ContactsTable() {
    const {locationId} = useParams()
    const url = `${mergeContactsUrl}${locationId}`
    const toast = useRef(null);
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isMounted, setIsMounted] = useState(false)
    const [duplicateType, setDuplicateType] = useState('full_name');
    const [mergeContactData, setMergeContactData] = useState(null);
    const [mergeContactCount, setMergeContactCount] = useState(null);
    const [mergeContactList, setMergeContactList] = useState(null);

    const types = [
        {
            label: 'Name',
            icon: 'pi pi-user',
            command: () => {
                setDuplicateType('full_name')
            }
        },
        {
            label: 'Email',
            icon: 'pi pi-envelope',
            command: () => {
                setDuplicateType('email')
            }
        },
        {
            label: 'Phone',
            icon: 'pi pi-phone',
            command: () => {
                setDuplicateType('phone_no')
            }
        }
    ];

    useEffect(()=>{
        setLoading(true);
        const data = { duplicateType }
        axios.post(url, data, {
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setCustomers(response.data);
                    setLoading(false);
                    initFilters();
                  }
                else {
                    setCustomers(null) 
                  }
                })
            .catch((error)=>{
                setCustomers(null)     
            })
    },[url, duplicateType, isMounted])

    const clearFilter = () => {
        initFilters();
    };

    const handMergeDataEmpty = () => {
        setMergeContactData(null) 
        setMergeContactList(null)
        setMergeContactCount(null)
        setIsMounted(!isMounted)
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phone_no: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            first_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            last_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            member_id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

    const redirectBody = (rowData) => {
        return(
            <div className="flex justify-content-center" style={{textAlign:'center'}}>
                <Button className="set-redirect-button" icon="pi pi-external-link" onClick={() => handleMerge(rowData)}/>
            </div>
        )
    }
    

    const handleMerge = (rowData) => {
        setLoading(true);
        let filteredData;
        let contactIds;
        if (duplicateType === 'full_name'){
            filteredData = customers.filter(item => item.full_name === rowData.full_name)
            contactIds = filteredData.map(item => item.id);
        }
        else if (duplicateType === 'email'){
            filteredData = customers.filter(item => item.email === rowData.email)
            contactIds = filteredData.map(item => item.id);
        }
        else {
            filteredData = customers.filter(item => item.phone === rowData.phone)
            contactIds = filteredData.map(item => item.id);
        }

        const data = {
                locationId,
                contactIds
            }
        setMergeContactCount(contactIds.length)
        setMergeContactList(contactIds)
        axios.post(mergeData, data, {
            headers: {'Content-Type': 'application/json'},
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setMergeContactData(response.data);
                    setLoading(false);
                    }
                else {
                    setMergeContactData(null) 
                    setLoading(false)
                    toast.current.show({ severity:'error', summary: 'Error', life: 3000});
                    }
                })
            .catch((error)=>{
                setMergeContactData(null)     
                setLoading(false)
                toast.current.show({ severity:'error', summary: 'Error', life: 3000});
            })
      };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            toast.current.show({ severity: 'info', summary: 'Copied' });
          })
          .catch(err => {
            console.error('Failed to copy the text to clipboard', err);
          });
      };

    const fullNameBody = (rowData) => {
        const fullName = rowData.full_name
        return(
            <div className='status'>
                {duplicateType === 'full_name' ? (
                    fullName ? 
                    <div>
                        <span className="copy-text" onClick={()=> copyToClipboard(fullName)}>{fullName}</span>
                        <i className="pi pi-copy copy-text" style={{ fontSize: '1.5rem', marginLeft: '.5rem' }} onClick={()=> copyToClipboard(fullName)}></i>
                    </div>
                    : 
                    <i className="pi">N/A</i>
                ) : (
                    fullName ? <span>{fullName}</span> : <i className="pi">N/A</i>
                )}
            </div>
        )
    }

    const emailBody = (rowData) => {
        const email = rowData.email
        return(
            <div className='status'>
                {duplicateType === 'email' ? (
                    email ? 
                    <div>
                        <span className="copy-text" onClick={()=> copyToClipboard(email)}>{email}</span>
                        <i className="pi pi-copy copy-text" style={{ fontSize: '1.5rem', marginLeft: '.5rem' }} onClick={()=> copyToClipboard(email)}></i>
                    </div>
                    : 
                    <i className="pi">N/A</i>
                ) : (
                    email ? <span>{email}</span> : <i className="pi">N/A</i>
                )}
            </div>
        )
    }

    const phoneBody = (rowData) => {
        const phone = rowData.phone_no
        return(
            <div className='status'>
                {duplicateType === 'phone_no' ? (
                    phone ? 
                    <div>
                        <span className="copy-text" onClick={()=> copyToClipboard(phone)}>{phone}</span>
                        <i className="pi pi-copy copy-text" style={{ fontSize: '1.5rem', marginLeft: '.5rem' }} onClick={()=> copyToClipboard(phone)}></i>
                    </div>
                    : 
                    <i className="pi">N/A</i>
                ) : (
                    phone ? <span>{phone}</span> : <i className="pi">N/A</i>
                )}
            </div>
        )
    }

    const memberBody = (rowData) => {
        const member_id = rowData.member_id
        return(
            <div className='status'>
                {member_id? <span>{member_id}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div className='status'>{rowIndex}</div>;
      };

    const header = renderHeader();

      // Define different sets of columns for each duplicateType
  const columnsByDuplicateType = {
    full_name: [
      { field: 'full_name', header: 'Name', sortable: true, filterField: 'full_name', filterPlaceholder: 'Search by name', body: fullNameBody },
      { field: 'email', header: 'Email', sortable: false, filterField: 'email', filterPlaceholder: 'Search by email', body: emailBody },
      { field: 'phone_no', header: 'Phone', sortable: false, filterField: 'phone_no', filterPlaceholder: 'Search by phone', body: phoneBody },
      { field: 'member_id', header: 'ABC Member ID', sortable: false, filterField: 'member_id', filterPlaceholder: 'Search by phone', body: memberBody },
      { field: 'full_name', header: 'Merge', sortable: false, filterField: 'full_name', filterPlaceholder: 'Search by phone', body: redirectBody },
      // Add other columns as needed
    ],
    email: [
      { field: 'email', header: 'Email', sortable: true, filterField: 'email', filterPlaceholder: 'Search by email', body: emailBody },
      { field: 'full_name', header: 'Name', sortable: false, filterField: 'full_name', filterPlaceholder: 'Search by name', body: fullNameBody },
      { field: 'phone_no', header: 'Phone', sortable: false, filterField: 'phone_no', filterPlaceholder: 'Search by phone', body: phoneBody },
      { field: 'member_id', header: 'ABC Member ID', sortable: false, filterField: 'member_id', filterPlaceholder: 'Search by phone', body: memberBody },
      { field: 'email', header: 'Merge', sortable: false, filterField: 'email', filterPlaceholder: 'Search by phone', body: redirectBody },
      // Add other columns as needed
    ],
    phone_no: [
      { field: 'phone_no', header: 'Phone', sortable: true, filterField: 'phone_no', filterPlaceholder: 'Search by phone', body: phoneBody },
      { field: 'full_name', header: 'Name', sortable: false, filterField: 'full_name', filterPlaceholder: 'Search by name', body: fullNameBody },
      { field: 'email', header: 'Email', sortable: false, filterField: 'email', filterPlaceholder: 'Search by email', body: emailBody },
      { field: 'member_id', header: 'ABC Member ID', sortable: false, filterField: 'member_id', filterPlaceholder: 'Search by phone', body: memberBody },
      { field: 'phone_no', header: 'Merge', sortable: false, filterField: 'phone_no', filterPlaceholder: 'Search by phone', body: redirectBody },
      // Add other columns as needed
    ],
  };

  // Select the appropriate set of columns based on duplicateType
  const selectedColumns = columnsByDuplicateType[duplicateType] || columnsByDuplicateType.full_name;

    return (
        duplicateType &&(
        <div className="card">
            <Toast ref={toast} />
            <TabMenu model={types} style={{ marginBottom: '1rem'}}/>
            <DataTable value={customers} scrollable scrollHeight="70vh" paginator showGridlines rows={50} rowsPerPageOptions={[50, 100]} loading={loading} dataKey="id" 
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    filters={filters} globalFilterFields={['email', 'phone_no', 'first_name', 'last_name', 'member_id', 'full_name']} header={header}
                    emptyMessage="No Contacts found." rowGroupMode="rowspan" groupRowsBy={duplicateType}>
                        
                <Column header="SR NO" className="padding-0" style={{ width: '2rem'  }} body={srNoBodyTemplate} />
                {selectedColumns.map((column, index) => (
                    <Column key={index} {...column} />
                ))}
                {/* <Column filterField="member_id" header="ABC Member ID" field="member_id" sortable  filterPlaceholder="Search by to" className="padding-0" style={{ width: '7rem'  }} body={memberBody}/> */}
                {/* <Column header="Open in CRM" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={redirectBody}/> */}
            </DataTable>
            {mergeContactData&&
                <MergeTable data={mergeContactData} handMergeDataEmpty={handMergeDataEmpty} mergeContactCount={mergeContactCount} mergeContactList={mergeContactList}/>
            }
        </div>
        )  
    );
}